document.addEventListener('DOMContentLoaded', function () {
	const inputs = document.querySelectorAll(
		'.form-group input, .form-group textarea'
	)
	inputs.forEach((input) => {
		input.addEventListener('focus', function () {
			this.parentElement.classList.add('form-group--focused')
		})
		input.addEventListener('blur', function () {
			this.parentElement.classList.remove('form-group--focused')
		})
	})
})
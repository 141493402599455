document.querySelectorAll('.button--scroll').forEach((button) => {
	button.addEventListener('click', function () {
		var targetId = this.getAttribute('data-target')
		var offset = parseInt(this.getAttribute('data-offset')) || 0 // Default offset is 0 if not specified
		var targetElement = document.getElementById(targetId)
		var targetPosition =
			targetElement.getBoundingClientRect().top + window.scrollY - offset

		window.scrollTo({
			top: targetPosition,
			behavior: 'smooth'
		})
	})
})